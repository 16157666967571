@font-face {
  font-family: "Supreme";
  src: url("../../Assets/Fonts/Supreme/Supreme-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Supreme";
  src: url("../../Assets/Fonts/Supreme/Supreme-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Supreme";
  src: url("../../Assets/Fonts/Supreme/Supreme-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Supreme";
  src: url("../../Assets/Fonts/Supreme/Supreme-Bold.otf");
  font-weight: 600;
}

* {
  font-family: "Supreme", Helevetica, sans-serif;
}

@use "../../styles/partials/reset.scss";
@use "../../styles/partials/typography.scss";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.home {
  background-color: $background-cream;
  padding-bottom: 1rem;
  padding-top: 5rem;

  @include tablet {
    max-width: 888px;
    margin: 0 auto;
  }

  &__wrapper {
    padding: 1rem 1.5rem;
    @include tablet {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
}

.intro {
  width: 100%;
  margin-bottom: 2rem;

  @include tablet {
    display: flex;
    flex-direction: row;
    max-width: 1020px;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__title {
    font-weight: 600;
    text-align: center;

    @include tablet {
      font-size: 2.25rem;
      text-align: start;
    }
  }

  &__wavy {
    letter-spacing: 0.05rem;
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-color: rgba(52, 126, 190, 0.6);
  }

  &__mobportrait {
    width: 10rem;
    border-radius: 50%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include tablet {
      display: none;
    }
  }

  &__portrait {
    display: none;

    @include tablet {
      display: flex;
      width: 11rem;
      border-radius: 50%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  &__text {
    font-weight: 500;
    font-size: 0.9rem;
    text-align: center;

    @include tablet {
      font-size: 1.25rem;
      text-align: start;
      margin-top: 0.5rem;

      overflow: hidden;
      border-right: 0.15em solid orange;
      white-space: nowrap;
      letter-spacing: 0.05em;
      animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
    }
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: orange;
    }
  }
}

.about {
  width: 100%;
  margin-bottom: 2.5rem;

  &__wrapper {
    max-width: 500px;
    margin: 0 auto;

    @include tablet {
      max-width: 1020px;
    }
  }

  &__title {
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-color: rgba(52, 126, 190, 0.6);
    padding-bottom: 0.5rem;
  }

  &__table {
    width: 100%;
    padding-top: 1.25rem;
  }

  &__tabs {
    width: 100%;

    @include tablet {
      display: flex;
      justify-content: center;
    }
  }

  &__tab {
    width: 50%;
    font-size: 0.875rem;
    font-weight: 600;
    color: gray;
    padding: 0.25rem 1rem;
    cursor: pointer;
    background-color: $background-cream;
    border: 0;
    outline: 0;

    &:hover {
      color: #bacbda;
    }

    &--active {
      border-radius: 3px;
      border-bottom: 2px solid rgba(52, 126, 190, 0.6);
      color: rgb(32, 77, 116);
    }

    @include tablet {
      width: 30%;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
  &__contents {
    padding: 0.5rem;

    @include tablet {
      width: 80%;
      margin: 0 auto;
    }
  }

  &__content {
    display: none;

    &--active {
      display: block;
    }
  }
}

.projects {
  width: 100%;
  margin-bottom: 2.5rem;

  &__wrapper {
    max-width: 500px;
    margin: 0 auto;

    @include tablet {
      max-width: 1020px;
    }
  }
  &__title {
    text-decoration-line: underline;
    text-decoration-style: wavy;
    text-decoration-color: rgba(52, 126, 190, 0.6);
    padding-bottom: 0.5rem;
  }
}

@use "../../styles/partials/reset.scss";
@use "../../styles/partials/typography.scss";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.footer {
  background-color: rgba(52, 126, 190, 0.75);
  color: $background-cream;

  &__container {
    padding: 3rem 1rem;
    padding-bottom: 4rem;
    margin: 0 auto;
    width: 400px;

    @include tablet {
      width: 700px;
    }
  }

  &__text {
    margin-bottom: 0.5rem;
  }
  &__socials {
    display: flex;
  }

  &__link {
    margin-right: 1rem;
    color: white;

    &:hover {
      font-weight: 500;
    }
  }

  &__tm {
    margin-top: 1rem;
    font-size: 0.65rem;
  }
}

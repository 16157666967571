@use "../../styles/partials/reset.scss";
@use "../../styles/partials/typography.scss";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.header {
  border-bottom: 2px rgba(128, 128, 128, 0.25) solid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: $background-cream;

  &__wrapper {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0.5rem;

    margin: 0 auto;

    @include tablet {
      width: 700px;
    }
  }

  &__viachu {
    text-decoration: none;
    border: none;
    background-color: transparent;
    color: rgba(52, 126, 190);
    &:hover {
      font-weight: 600;
    }
  }

  &__link {
    text-decoration: none;
    color: black;
    border-bottom: 1px solid white;

    &:hover {
      border-bottom: 2px solid rgba(52, 126, 190, 0.6);
      border-radius: 3px;
    }

    &--active {
      border-bottom: 2px solid rgb(19, 81, 135);
      border-radius: 3px;
    }
  }
  &__name {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @include tablet {
      font-size: 1.125rem;
    }
  }

  &__resume {
    display: flex;
    @include tablet {
      font-size: 1.125rem;
    }
  }

  &__subname {
    padding-left: 0.5rem;
  }

  &__icon {
    width: 0.85rem;
    padding-top: 0.1rem;
    align-self: flex-start;
  }
}

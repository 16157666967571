@use "../../styles/partials/reset.scss";
@use "../../styles/partials/typography.scss";
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.project {
  border-radius: 1rem;
  background-color: rgb(255, 255, 246);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;

  @include tablet {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
  }

  &__wrapper {
    border-radius: 1rem;

    @include tablet {
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    text-align: center;
    padding: 1rem;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: orange;
  }
  &__container {
    @include tablet {
      display: flex;
    }
  }

  &__left {
    width: 100%;
    margin-bottom: 0.75rem;

    @include tablet {
      width: 50%;
    }
  }

  &__right {
    @include tablet {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }

  &__button {
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &__text {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 30%;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  &__tag {
    width: fit-content;
    background-color: #fff;
    border-radius: 24px;
    color: #3c4043;
    border: 2px solid rgba(52, 126, 190, 0.6);
    padding: 0.25rem 0.5rem;
    margin-top: 0.25rem;
    margin-right: 0.5rem;

    &:hover {
      background: #f6f9fe;
      color: #174ea6;
    }
  }
  &__github {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
    color: black;
    font-weight: 500;
    &:hover {
      color: orange;
    }

    @include tablet {
      margin-top: 2rem;
      align-items: end;
    }
  }
  &__ghicon {
    width: 1.5rem;
    margin-right: 0.25rem;
  }
}

.pocket {
  &__image {
    width: 11rem;
    margin: 0 auto;
  }
}

.slick-slider {
  position: relative;
  height: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 20px;
}
.slick-prev,
.slick-next {
  background: orange !important;
  position: relative !important;
  border-radius: 1rem !important;
  @include tablet {
    top: 0% !important;
  }
}

.slick-prev {
  left: -2px !important;
  @include tablet {
    left: 15px !important;
  }
}

.slick-next {
  right: -2px !important;
  @include tablet {
    right: 15px !important;
  }
}

.slick-dots {
  bottom: -0.5rem !important;
  @include tablet {
    bottom: -1rem !important;
  }
}
